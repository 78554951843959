import { Col, Container, Row } from "react-bootstrap"
import { Collection, Metafield, metafield_keys } from "@utils/globalTypes"
import React, { FC } from "react"

import ProductCard from "@components/collections/ProductCard"
import classnames from "classnames"
import * as s from "./ProductCardsContainer.module.scss"

type Props = {
  collection: Collection
  metafields: Metafield[]
}

const ProductCardsContainer: FC<Props> = ({ collection, metafields }) => {
  const { products } = collection

  const metafield = metafields.find(
    (edge) => edge.key === metafield_keys.headline
  )

  const headline = metafield ? metafield.value : "Top Collection Products"

  const cardContainerClasses = classnames("ml-0", "mb-4", s.card)

  if (!products || !products.length) {
    return <></>
  }

  return (
    <Container className={s.cardContainer}>
      <Row className="justify-content-center">
        <Col xs={12}>
          <h2 className={s.title}>{headline}</h2>
        </Col>
        {products.map((product, index) => (
          <Col
            key={index}
            className={cardContainerClasses}
            xs={12}
            md={6}
            lg={3}
          >
            <ProductCard {...product} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default ProductCardsContainer
